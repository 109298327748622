<template>
  <span>
    <b-row v-if="toolbar">
      <b-col></b-col>
      <b-col cols="12" md="6" class="text-right">
        <b-form-group>
          <slot name="action-toolbar" />
          <span v-if="toggleQuickEdit">
            <b-form-checkbox
              v-model="isTogglingQuickEdit"
              checked="true"
              name="check-button"
              switch
              inline
            >
              Toggle Quick Edit
            </b-form-checkbox>
          </span>

          <span v-if="toggleView">
            <b-form-checkbox
              v-model="isTogglingView"
              checked="true"
              name="check-button"
              switch
              inline
              @change="$emit('onToggleView', $event)"
            >
              Toggle Quick View
            </b-form-checkbox>
          </span>
          <span v-if="canAdd">
            <b-button
              size="sm"
              v-if="url"
              variant="primary"
              @click="$router.push(url)"
            >
              Add
            </b-button>
            <b-button
              size="sm"
              v-else
              variant="primary"
              @click="$emit('on-open-modal', { name: 'add', value: true })"
            >
              Add
            </b-button>
          </span>

          <slot name="more_action_button" />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="6">
        <h1>{{ title }}</h1>
      </b-col>
      <b-col cols="12" md="4" class="text-right">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" @click="onSearch()" />
            </b-input-group-prepend>
            <b-form-input
              v-model="keyword"
              placeholder="Search"
              @change="onSearch()"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="2" class="text-right">
        <b-form-group>
          <v-select
            placeholder="Status"
            :options="[
              { label: 'Open', value: 'O' },
              { label: 'Archive', value: 'V' },
            ]"
            :reduce="(option) => option.value"
            @option:selected="$emit('on-status-changed', $event.value)"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group>
          <DxDataGrid
            key-expr="id"
            id="gridContainer"
            :data-source="dataSource"
            :focused-row-enabled="true"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :show-borders="true"
            :row-alternation-enabled="true"
            @selection-changed="onSelectionChanged"
            @exporting="onExporting"
            @row-removed="handleEvent('row-removed', $event)"
            @row-updated="handleEvent('row-updated', $event)"
            @edit-canceling="handleEvent('edit-canceling', $event)"
            @edit-canceled="handleEvent('edit-canceled', $event)"
            @row-click="handleEvent('row-click', $event)"
            @option-changed="handleEvent('option-changed', $event)"
          >
            <DxPaging :page-size="totalCount" />

            <DxColumnChooser
              v-if="hasColumnChooser"
              :allow-search="true"
              :enabled="true"
            />
            <DxColumnFixing :enabled="true" />
            <DxSelection :show-check-boxes-mode="canSelect" mode="multiple" />
            <DxSorting mode="multiple" />
            <DxFilterRow :visible="true" />
            <DxGroupPanel :visible="true" />
            <DxGrouping :auto-expand-all="true" />
            <!-- <DxHeaderFilter :visible="true" /> -->
            <!-- <DxSearchPanel location="before" :visible="true" /> -->

            <DxEditing
              :allow-updating="isTogglingQuickEdit"
              :allow-deleting="true"
              :use-icons="true"
              mode="row"
            />

            <DxColumn type="buttons">
              <slot name="action-buttons" :data="dataSource" />

              <DxButton
                ref="editRef"
                v-if="isEditable && !isTogglingQuickEdit && getRole"
                icon="edit"
                hint="Edit"
                :onClick="editData"
              />
              <DxButton v-if="isEditable && isTogglingQuickEdit" name="edit" />
              <DxButton name="delete" />
            </DxColumn>

            <slot name="columns" />
            <slot name="summaries" />

            <DxExport
              v-if="hasExport"
              :enabled="true"
              :formats="['pdf', 'xlsx']"
              :allow-export-selected-data="false"
              file-name="rfqlist"
            />

            <DxToolbar>
              <DxItem location="before" name="columnChooserButton" />
              <DxItem location="before" name="exportButton" />
              <!-- <DxItem template="checkboxSelectTemplate" /> -->
              <DxItem location="before" name="searchPanel" />
              <DxItem name="groupPanel" />
            </DxToolbar>

            <template #checkboxSelectTemplate>
              <DxSelectBox
                :items="[
                  { text: 'Confirm', value: 'C' },
                  { text: 'Reopen', value: 'O' },
                  { text: 'Void', value: 'V' },
                ]"
                display-expr="text"
                value-expr="value"
              />
            </template>

            <template #columnTemplate="{ data }">
              {{ displayDate(data.value) }}
            </template>
          </DxDataGrid>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-nav>
          <b-nav-item
            @click="onSetPage(page)"
            v-for="(page, index) in pageSize"
            :key="index"
          >
            <span :class="page.isActive ? 'text-success' : ''">
              {{ page.name }}
            </span>
          </b-nav-item>
        </b-nav>
      </b-col>
      <b-col>
        <b-pagination
          align="right"
          v-model="currentPage"
          :total-rows="totalCount"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
        />
      </b-col>
    </b-row>

    <b-modal v-model="showModal" centered title="title">
      <b-card-text>
        <h5>title</h5>
        Do you want to proceed?
      </b-card-text>

      <template #modal-footer="{ close, cancel }">
        <b-button
          variant="danger"
          class="bg-darken-4"
          size="sm"
          @click="
            cancel();
            updatedData = null;
            $emit('on-update-cancel', true);
          "
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          class="bg-darken-4"
          size="sm"
          @click="
            close();
            acceptModalAction();
          "
        >
          Accept
        </b-button>
      </template>
    </b-modal>
  </span>
</template>

<script>
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';
import { queryParameters } from '@/schema';
import { getUserData } from '@/auth/utils';

export default {
  name: 'DxGrid',
  props: {
    title: {
      type: String,
      default: null,
    },
    toolbar: {
      type: Boolean,
      default: true,
    },
    dataSource: {
      type: Array,
      default: () => [],
    },
    url: {
      type: String,
      default: null,
    },
    canAdd: {
      type: Boolean,
      default: true,
    },
    canSelect: {
      type: String,
      default: 'none',
    },
    toggleView: {
      type: Boolean,
      default: false,
    },
    toggleQuickEdit: {
      type: Boolean,
      default: false,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    hasColumnChooser: {
      type: Boolean,
      default: true,
    },
    hasExport: {
      type: Boolean,
      default: true,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    isUUID: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getRole: {
      get() {
        const userData = getUserData();
        const { role } = userData;

        const roles = ['admin', 'custadmin'];
        return roles.includes(role);
      },
    },
  },
  watch: {
    currentPage(v) {
      this.$emit('on-page-change', v);
    },
  },
  data: () => ({
    currentPage: 1,
    perPage: 0,
    pageSize: [
      { name: '10', value: 10, isActive: false },
      { name: '20', value: 20, isActive: false },
      { name: '50', value: 50, isActive: false },
      { name: '100', value: 100, isActive: false },
    ],
    isTogglingView: false,
    isTogglingQuickEdit: false,
    showModal: false,
    employees: [],
    companies: [],
    statuses: [
      { name: 'New', value: 'N' },
      { name: 'Open', value: 'O' },
      // { name: 'Processing', value: 'P' },
      { name: 'Reopen', value: 'R' },
      { name: 'Confirmed', value: 'C' },
      { name: 'Void', value: 'V' },
    ],
    updatedData: {},
    keyword: '',
  }),
  created() {
    this.onSetPage({ value: queryParameters.page.size });
  },
  methods: {
    editData(event) {
      if (!this.url) {
        this.$emit('on-open-modal', {
          name: 'edit',
          value: true,
          data: event?.row?.data,
        });
        return;
      }

      let id = event.row.key;

      const { data } = event.row;

      if (this.isUUID) {
        id = event.row.data.uuid;
      }

      if (this.url.includes('job-order/manage')) {
        const { job_status } = data;

        if (!['0', '1'].includes(job_status)) {
          const msg = 'Job cannot be modified due to JOB STATUS';
          this.toastConfig(`${msg}: ${this.getJobStatus(job_status)}`, 'error');
          return;
        }

        this.$router.push(`${this.url}?id=${id}`);
        return;
      }

      if (this.url.includes('service-request/manage')) {
        const { joborder_header_id } = data;

        if (joborder_header_id) {
          const msg = 'Service Request cannot be modified';
          this.toastConfig(`${msg}`, 'error');
          return;
        }

        this.$router.push(`${this.url}?id=${id}`);
        return;
      }

      this.$router.push(`${this.url}?id=${id}`);
    },
    handleEvent(action, event) {
      if (action === 'option-changed') {
        this.$emit('on-option-changed', event);
      }

      if (action == 'row-removed') {
        this.$emit('on-delete', event.key);
      }

      if (action == 'row-updated') {
        if (event) {
          this.updatedData = event;
          this.showModal = true;
        }
      }

      if (action === 'row-click') {
        this.$emit('on-row-click', event);
      }
    },
    displayDate(value) {
      return value;
    },
    acceptModalAction() {
      this.$emit('on-update', this.updatedData);
    },

    onSetPage(event) {
      for (let i = 0; i < this.pageSize.length; i++) {
        const page = this.pageSize[i];

        page.isActive = event.value === page.value;
      }

      this.$emit('on-size-change', event.value);
      this.perPage = event.value;
    },
    onReorder(e) {
      const visibleRows = e.component.getVisibleRows();
      const toIndex = this.dataSource.indexOf(visibleRows[e.toIndex].data);
      const fromIndex = this.dataSource.indexOf(e.itemData);
      const newDataSource = [...this.dataSource];

      newDataSource.splice(fromIndex, 1);
      newDataSource.splice(toIndex, 0, e.itemData);

      this.dataSource = newDataSource;
    },
    onExporting(e) {
      const doc = new jsPDF();

      if (e.format == 'pdf') {
        exportDataGrid({
          jsPDFDocument: doc,
          component: e.component,
          indent: 5,
        }).then(() => {
          doc.save('rfqlist.pdf');
        });
      }
    },
    onSelectionChanged(e) {
      this.$emit('onSelectionChanged', e.selectedRowsData);
    },
    onSearch() {
      if (this.keyword) {
        this.$emit('on-search', this.keyword);
        return;
      }

      this.$emit('on-search', null);
    },
  },
};
</script>

<style scoped>
.right-side {
  position: absolute;
  right: 1px;
  top: 6px;
}

.logo {
  line-height: 48px;
}

.logo img {
  vertical-align: middle;
  margin: 0 5px;
}

.logo img:first-child {
  margin-right: 9px;
}

.dx-row.dx-data-row .employee {
  /* color: #bf4e6a;
  font-weight: bold; */
}

#gridContainer {
  /* margin: 20px 0; */
  height: 740x;
}
</style>
